@font-face {
  font-family: "Oxygen";
  src: url("../../assets/fonts/Oxygen-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Oxygen";
  src: url("../../assets/fonts/Oxygen-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Oxygen-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "norseregular";
  src: url("../../assets/fonts/norse-webfont.woff");
  font-weight: 400;
}

@font-face {
  font-family: "Syncopate";
  src: url("../../assets/fonts/Syncopate-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Trillium-Web";
  src: url("../../assets/fonts/TitilliumWeb-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Avenir";
  src: url("../../assets/fonts/AvenirNextLTPro-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Helvetica";
  src: url("../../assets/fonts/HelveticaNeueMediumExtended.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Pretendo";
  src: url("../../assets/fonts/Pretendo.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "MonteCarlo";
  src: url("../../assets/fonts/MonteCarlo-Regular.ttf");
  font-weight: 400;
}

// @font-face {
//   font-family: "Roboto";
//   src: url("../../assets/fonts/Roboto-Regular.ttf");
//   font-weight: 400;
// }

$font-stack: "Oxygen", sans-serif;
$font-odin: "norseregular", sans-serif;
$font-broken-bottts: "Syncopate", sans-serif;
$font-instock: "Trillium-Web";
$font-brainflix: "Avenir", "sans-serif";
$font-industryHackathon: "Helvetica", "sans-serif";
$font-pretendo: "Pretendo", "sans-serif";
$font-puddl: "Quicksand", "sans-serif";
$font-members-only: "Roboto", "sans-serif";
$font-bread: "MonteCarlo", "cursive";
