@use "./styles/partials/global" as *;
@use "./styles/partials/colours" as *;
@use "./styles/partials/mixins" as *;

.app-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &--light {
    background-color: $secondary-two;
    color: $primary-two;
  }

  &--dark {
    background-color: $secondary-one;
    color: $primary-one;
  }
}

.app {
  padding: 1rem 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 1150px;
  box-sizing: border-box;
  @include tablet {
    justify-content: space-between;
    flex-direction: row;
  }
}
