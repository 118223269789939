@use "../../styles/partials/colours" as *;
@use "../../styles/partials/mixins" as *;

.btn {
  margin: 1rem 0;
  width: 100%;
  background: $secondary-one;
  border: 1px solid $secondary-three;
  color: $secondary-three;
  padding: 0.3rem;
  border-radius: 3px;
  font-weight: bold;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    background: $secondary-three;
    color: $secondary-one;
  }
  &--light {
    background: $primary-one;
    border: 1px solid $secondary-four;
    color: $secondary-four;
    &:hover {
      background: $secondary-four;
      color: $primary-one;
    }
  }
  &--mobile {
    @include tablet {
      display: none;
    }
  }
  &--desktop {
    display: none;
    @include tablet {
      display: block;
    }
  }
}
