@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/colours" as *;
@use "../../styles/partials/typography" as *;

.project {
  margin-top: 1rem;
  &__header {
    display: flex;
    flex-direction: column;
  }
  &__title-wrapper {
    display: flex;
    flex-direction: column;
    @include tablet {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__title {
    font-size: 1.5rem;
    margin: 0 0 0.5rem 0;
    &--broken-bottts {
      font-family: $font-broken-bottts;
      color: $broken-bottts;
    }
    &--instock {
      font-family: $font-instock;
      color: $instock;
    }
    &--brainflix {
      font-family: $font-brainflix;
      color: $brainflix;
    }
    &--industry-hackathon {
      font-family: $font-industryHackathon;
      color: $industryHackathon;
    }
    &--first-hackathon {
      font-family: $font-pretendo;
      color: $firstHackathon;
    }
    &--puddl {
      font-family: $font-puddl;
      color: $puddl;
    }
    &--members-only {
      font-family: $font-members-only;
      color: $members-only;
    }
    &--blog {
      color: $blog;
    }
    &--bread {
      font-family: $font-bread;
      color: $bread;
    }
  }
  &__description {
    font-size: 1.15rem;
  }
  &__technologies {
    font-style: italic;
    text-align: left;
    @include tablet {
      text-align: right;
    }
  }
  &__date {
    font-style: italic;
    @include tablet {
      text-align: right;
      margin: 0;
    }
  }
  &__details {
    &--no-imgs {
      margin-bottom: 3rem;
    }
  }
  &__detail-wrapper {
    margin-top: 1rem;
    display: flex;
    align-items: center;
  }
  &__detail {
    font-size: 1.15rem;
    padding-left: 1rem;
  }

  &__detail-icon {
    min-width: 1.25rem;
  }
  &__imgs-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    &--mobile {
      flex-direction: row;
      justify-content: space-around;
    }
  }
  &__img-wrapper {
    position: relative;
    &--mobile {
      aspect-ratio: 1/2;
      width: 45%;
      max-width: 300px;
      @include tablet {
        width: 30%;
      }
    }
  }
  &__img {
    width: 100%;
    aspect-ratio: 2/1.2;
    margin-bottom: 3rem;
    border: 3px solid $secondary-three;
    border-radius: 0.5rem;
    position: relative;
    &--light {
      border-color: $secondary-four;
    }
    &--mobile {
      width: 100%;
      height: 100%;
    }
  }
}
