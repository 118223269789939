@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/colours" as *;
@use "../../styles/partials/typography" as *;

.header-wrapper {
  position: relative;
  z-index: 2;
}

.header {
  @include tablet {
    height: max-content;
    width: 300px;
  }
  &__name-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid $secondary-two;
    margin: 0.5rem 0.5rem 1.5rem 0;
    padding-bottom: 0.5rem;
    &--light {
      border-color: $secondary-one;
    }
  }
  &__name {
    @include heading;
    border: none;
    padding: 0;
    margin: 0 0.5rem 0.25rem 0;
    @include tablet {
      margin: 0;
    }
    &::after {
      position: relative;
      left: 0.25rem;
      content: "_";
      animation: flashingUnderscore 2s infinite;
    }
  }
  .switch {
    &--top {
      margin-bottom: 0.25rem;
      @include tablet {
        display: none;
      }
    }
    &--bottom {
      display: none;
      @include tablet {
        display: block;
      }
    }
  }
  &__title {
    font-size: 1.5rem;
  }
  &__contact-details {
    display: flex;
    flex-wrap: wrap;
  }
  &__contact-detail {
    text-decoration: none;
    display: flex;
    align-items: center;
    width: max-content;
    margin-right: 1rem;
    margin-bottom: 1rem;
    &--dark {
      color: $primary-one;
      @include underlineEffect;
    }
    &--light {
      color: $primary-two;
      @include underlineEffect;
      &:after {
        background-color: $primary-two;
      }
    }
  }
  &__contact-icon {
    min-width: 1.1rem;
    padding-right: 0.1rem;
  }
}

@keyframes flashingUnderscore {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
