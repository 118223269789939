$primary-one: white;
$primary-two: rgb(0, 0, 0);
$primary-three: rgb(6, 0, 174);
$primary-four: #cc9543;
$primary-five: #840143;

$secondary-one: rgb(18, 18, 18);
$secondary-two: rgb(255, 255, 255);
// $secondary-three: #05f60590;
$secondary-three: #9225ff;
$secondary-four: #05aaf6;

$broken-bottts: #ee5a1b;
$instock: #2e66e5;
$brainflix: #0095ff;
$industryHackathon: #95bf46;
$firstHackathon: #ff0000;
$puddl: #5d92d0;
$members-only: #b19600;
$blog: #6574bb;
$bread: #a39b6c;
