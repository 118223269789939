@use "./colours" as *;

@mixin mediumPhone {
  @media screen and (min-width: 450px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 1000px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin heading {
  font-size: 2.25rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $secondary-two;
}

@mixin underlineEffect {
  display: inline-block;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $primary-one;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}
