@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/colours" as *;
@use "../../styles/partials/typography" as *;

.projects-section {
  scroll-behavior: smooth;
  z-index: 2;
  font-size: 1.15rem;
  @include tablet {
    flex-grow: 1;
    height: calc(100vh - 2rem);
    overflow-y: auto;
    padding: 0 2rem;
  }
  &__title {
    @include heading;
    margin: 0.5rem 0 1.5rem;
    &--light {
      border-color: $secondary-one;
    }
  }
}

.education {
  font-size: 1.15rem;
  &__heading {
    @include heading;
    margin: 0.5rem 0 1.5rem;
    padding-bottom: 0.5rem;
    &--light {
      border-color: $secondary-one;
    }
  }
  &__item {
    display: flex;
    font-size: 1rem;
    align-items: initial;
    flex-direction: column;
    @include mediumPhone {
      margin-bottom: 1rem;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  &__title-logo-wrapper {
    display: flex;
    align-items: center;
  }
  &__logo {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
  &__link {
    @include underlineEffect;
    &--light {
      &:after {
        background-color: $primary-two;
      }
    }
  }
  &__title {
    margin: 0;
    &--brainstation {
      color: $instock;
    }

    &--odin-project {
      color: $primary-four;
      font-family: $font-odin;
    }
    &--mcmaster {
      color: $primary-five;
    }
  }
  &__date {
    margin: 0.5rem 0;
    @include mediumPhone {
      margin: 0;
    }
    @include tablet {
      margin: 0.25rem 0;
    }
  }
  &__paragraph {
    display: flex;
    flex-direction: column;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid #888;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}
